body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}

.logout-button {
  float: right;
  margin: 18px;
}

.ScoresheetDataRow {
  padding: 20px;
}

.ScoresheetTextRow {
  padding: 0px 20px 0px 20px;
}

.IconPrefix {
  color: rgba(0, 0, 0, 0.25);
}

.StatsTable {
  padding: 1px !important;
}
